export default {
  namespaced: true,
  state: {
    customerType: '',
    gender: '',
    // 抽奖次数
    leftDrawCount: 0,
    mobile: '',
    realName: '',
    storeName: '',
  },
  mutations: {
    updateCustomerType(state, customerType) {
      state.customerType = customerType
    },
    updateGender(state, gender) {
      state.gender = gender
    },
    updateLeftDrawCount(state, leftDrawCount) {
      state.leftDrawCount = leftDrawCount
    },
    updateMobile(state, mobile) {
      state.mobile = mobile
    },
    updateRealName(state, realName) {
      state.realName = realName
    },
    updateStoreName(state, storeName) {
      state.storeName = storeName
    }
  },
  getters: {
    getCustomerType(state) {
      return state.customerType
    },
    getGender(state) {
      return state.gender
    },
    getLeftDrawCount(state) {
      return state.leftDrawCount
    },
    getMobile(state) {
      return state.mobile
    },
    getRealName(state) {
      return state.realName
    },
    getStoreName(state) {
      return state.storeName
    }
  },
  actions: {
    // 抽奖次数减一
    decrementLeftDrawCount({ commit, state }) {
      commit('updateLeftDrawCount', state.leftDrawCount - 1)
    }
  }
}
