<template>
  <div class="container">
    <div class="bg">
      <div class="loginOut" @click="loginOut">退出登录</div>
      <div
        class="userInfo commonTop"
        @click="changeMode('user')"
        :class="{ showTop: mode === 'raffle' || mode === 'rules' }"
      >
        <img :src="`${$baseUrl}/avatar${gender}.png`" alt="" />
        <div>
          <div class="name">{{ realName }}</div>
          <div class="phone">{{ mobile }}</div>
        </div>
      </div>
      <div
        @click="changeMode('rules')"
        class="explain commonTop"
        :class="{ showTop: mode === 'user' }"
      >
        活动说明 >
      </div>
      <!-- 用户信息区域 -->
      <UserView class="userBoxPos" :class="{ userBoxShow: mode === 'user' }" />
      <!-- 规则面板 -->
      <RulesView
        class="rulesBoxPos"
        :class="{ rulesBoxShow: mode === 'rules' }"
      />
      <!-- 中奖记录 -->
      <MyWinRecordView
        ref="myWinRecordView"
        class="rulesBoxPos"
        :class="{ rulesBoxShow: mode === 'myWinRecord' }"
      />
      <!-- 轮盘区域 -->
      <div class="diskBox" :class="{ showDiskBox: mode === 'raffle' }">
        <div
          class="disk"
          :style="{
            transform: `rotate(${diskRotate}deg)`,
            transition: `transform ${timeOut / 1000}s ${bezierFun}`,
          }"
        >
          <!-- 轮盘内容 -->
          <div
            class="content"
            v-for="(item, index) in diskDataAll"
            :key="index"
          >
            <div
              class="item"
              :style="{ transform: `rotate(${index * 45 + 22.5}deg)` }"
            >
              <div
                class="name"
                :style="{ lineHeight: item.count ? '' : '0.9rem' }"
              >
                {{ item.name }}
              </div>
              <div v-if="item.count" class="count">
                {{ `(${item.count}名)` }}
              </div>
              <img v-if="item.imageUrl" :src="item.imageUrl" alt="" />
            </div>
          </div>
        </div>
        <!-- 指针 -->
        <div class="pointer"></div>
      </div>
      <!-- 抽奖按钮 -->
      <div
        class="btn"
        @click="raffle()"
        :class="{
          btnCountShow: mode !== 'raffle',
          btnRulesPos: mode === 'rules' || mode === 'myWinRecord',
        }"
      >
        <span class="btnTitle">立即抽奖</span>
        <span class="btnCount">剩余{{ leftDrawCount }}次</span>
      </div>
      <div class="record" @click="changeMode('myWinRecord')">
        我的中奖记录 >
      </div>
      <!-- 公布栏 -->
      <div
        v-if="publicData.length > 0"
        class="publicBox"
        :class="{ publicBoxShow: mode === 'raffle' }"
      >
        <!-- 无限循环的有限列表 -->
        <div class="publicContent">
          <div :class="{ publicList: publicData.length >= 5 }">
            <div
              class="publicItem"
              v-for="(item, index) in publicDataLoop"
              :key="index"
            >
              <span>{{ item.customerInfo }}</span
              ><span>{{ item.prizeInfo }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 0.28rem"></div>
      <RaffleMsgView
        v-if="RaffleInfo"
        :RaffleInfo="RaffleInfo"
        :leftDrawCount="newLeftDrawCount"
        @closeMsg="closeMsg"
        @refresh="refresh"
      />
    </div>
  </div>
</template>

<script>
import UserView from "./UserView.vue";
import RulesView from "./RulesView.vue";
import RaffleMsgView from "./RaffleMsgView.vue";
import MyWinRecordView from "./MyWinRecordView.vue";
import { mapState } from "vuex";
export default {
  components: { UserView, RulesView, RaffleMsgView, MyWinRecordView },
  data() {
    return {
      mode: "user",
      // 奖品数据
      diskData: [],
      // 公告数据(其他人中奖记录)
      publicData: [],
      // 谢谢惠顾信息
      thanksInfo: "谢谢惠顾",
      diskRotate: 0, // 转盘角度
      offset: 7, // 偏移角度(当前项目请勿大于22.5)
      timeOut: 5000, // 抽奖时间
      coolDown: 1000, // 冷却时间
      baseDeg: 3600, // 基础角度(10圈起步)
      bezierFun: "cubic-bezier(0.38,0.1,0,1)", //缓动函数
      timerId: null,
      oldRandomDeg: 0,
      curRotate: 0,
      RaffleInfo: null,
      RaffleInfoTimer: null,
      newLeftDrawCount: 0,
    };
  },
  mounted() {
    this.getPrizeList();
    this.getWinRecord();
  },
  computed: {
    diskDataAll() {
      return this.diskData.reduce((prev, curr) => {
        prev.push(curr);
        prev.push({ name: this.thanksInfo, flag: 5 });
        return prev.map((item, index) => {
          return {
            ...item,
            rotate: index * 45 + 22.5,
          };
        });
      }, []);
    },
    publicDataLoop() {
      if (this.publicData.length < 5) {
        return this.publicData;
      } else {
        return this.publicData.concat(this.publicData);
      }
    },
    ...mapState({
      gender: (state) => state.user.gender,
      leftDrawCount: (state) => state.user.leftDrawCount,
      mobile: (state) => state.user.mobile,
      realName: (state) => state.user.realName,
    }),
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
    },
    raffle() {
      // 切换模式
      if (this.mode !== "raffle") {
        this.mode = "raffle";
        return;
      }
      // 冷却是否结束
      if (this.timerId) return;
      this.timerId = setTimeout(() => {
        clearTimeout(this.timerId);
        this.timerId = null;
      }, this.timeOut + this.coolDown);

      // 判断是否在可抽奖
      this.$http({
        url: this.$http.adornUrl("/customer/canBegin"),
        method: "post",
      }).then((data) => {
        console.log(data.data);
        if (!data.data) {
          alert("您当前无法抽奖");
        }
        // 开始抽奖
        this.$http({
          url: this.$http.adornUrl("/customer/draw"),
          method: "get",
          // 测试模拟不同的用户，上线时不传customerId参数
          // params: this.$http.adornParams({ customerId: 4 }),
        }).then((data) => {
          const flag = this.isRaffleNumber(data.data) ? data.data : null;
          if (data.code === 500) return;
          this.RaffleInfoTimer = setTimeout(() => {
            // this.RaffleInfo = "恭喜137****2545抽中一等奖手机一台";
            // this.RaffleInfo = { name: "谢谢惠顾", flag: 5 };
            // this.RaffleInfo = { name: "精美小礼品", flag: 5, count: 1000 };
            // this.RaffleInfo = { name: "吹风机", flag: 2, count: 5 };
            this.RaffleInfo = this.diskDataAll.find(
              (item) => item.flag === data.data
            );
            this.newLeftDrawCount = this.leftDrawCount - 1;
            // 抽中后刷新中奖记录
            this.getWinRecord();
            this.$refs.myWinRecordView.getMyWinRecord();
            clearTimeout(this.RaffleInfoTimer);
            this.RaffleInfoTimer = null;
          }, this.timeOut);
          const nothingDegArr = this.diskDataAll
            .filter((item) => {
              return item.flag === 5;
            })
            .map((item) => item.rotate);
          let targetDeg;
          if (flag) {
            targetDeg = this.diskDataAll.find(
              (item) => item.flag === flag
            ).rotate;
          } else {
            console.log("sadfasfd");
            targetDeg =
              nothingDegArr[Math.floor(Math.random() * nothingDegArr.length)];
            console.log(nothingDegArr);
          }
          this.diskRotate +=
            this.baseDeg + this.curRotate - targetDeg + this.randomDeg();
          this.curRotate = targetDeg;
        });
      });
    },
    randomDeg() {
      const randomDeg =
        Math.floor(Math.random() * (this.offset * 2 + 1)) - this.offset;
      const oldCount = this.oldRandomDeg;
      this.oldRandomDeg = randomDeg;
      return randomDeg - oldCount;
    },
    // 获取远程奖品数据/customer/drawPrizeList
    getPrizeList() {
      this.$http({
        url: this.$http.adornUrl("/customer/drawPrizeList"),
        method: "get",
      }).then((data) => {
        this.diskData = data?.data.filter((item) => {
          return item.flag !== 5;
        });
        this.thanksInfo = data?.data.find((item) => item.flag === 5).prizeName;
      });
    },
    // 获取中奖公告列表/customer/winRecord
    getWinRecord() {
      this.$http({
        url: this.$http.adornUrl("/customer/winRecord"),
        method: "get",
      }).then((data) => {
        this.publicData = data?.data;
      });
    },
    // 是否为中奖号码
    isRaffleNumber(num) {
      return num === 1 || num === 2 || num === 3 || num === 4;
    },
    closeMsg() {
      this.RaffleInfo = null;
      // 抽奖次数减一
      this.$store.commit("user/updateLeftDrawCount", this.leftDrawCount - 1);
    },
    // 刷新
    refresh() {
      this.getPrizeList();
      this.getWinRecord();
    },
    loginOut() {
      // 确认框
      const result = confirm("确认退出吗");
      if (result) {
        this.$cookie.delete("token");
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #b21d13;
}
.bg {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  padding-top: 1.12rem;
  position: relative;
}
.showDiskBox {
  transform: scale(1) !important;
}
.diskBox {
  width: 7rem;
  height: 7rem;
  position: relative;
  margin: 0 auto;
  transform: scale(0);
  transition: all 0.5s;
}
.disk {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/disk.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
}
.pointer {
  width: 2rem;
  height: 2rem;
  background-image: url("../assets/img/pointer.png");
  background-size: cover;
  position: absolute;
  top: 2.29rem;
  left: 50%;
  transform: translateX(-50%);
}
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.item {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-top: 1.07rem;
  box-sizing: border-box;
  align-items: center;
  color: #c21c1b;
  transform-origin: 50% 100%;
}
.item img {
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  margin-top: 0.1rem;
}
.name {
  font-size: 0.26rem;
  font-weight: 800;
}
.count {
  font-size: 0.22rem;
}
.btn {
  width: 6rem;
  height: 1.5rem;
  background-image: url("../assets/img/btn.png");
  background-size: contain;
  margin: 0 auto;
  margin-top: 2.13rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: margin-top 0.7s;
}
.btnRulesPos {
  margin-top: 3.5rem;
}
.btn .btnTitle {
  transition: all 0.3s;
  line-height: 0.7rem;
  font-weight: bold;
  font-size: 0.51rem;
  color: #d72822;
}
.btn .btnCount {
  font-size: 0.24rem;
  color: #884606;
}
.publicBox {
  width: 6.27rem;
  height: 0rem;
  background-color: #b21d13;
  border-radius: 0.2rem;
  box-sizing: border-box;
  padding: 0.42rem 0.36rem;
  margin: 0 auto;
  margin-top: 0.26rem;
  transition: all 0.7s;
}
.publicBoxShow {
  height: 4.1rem;
  background: #c52a25;
}
.publicContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.publicList {
  animation: scroll 7s linear infinite;
}
.publicItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  color: #ffee9c;
  height: 0.67rem;
}
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}
.showTop {
  transform: scaleX(1) !important;
}
.commonTop {
  position: absolute;
  right: 0;
  width: 0rem;
  background: #e94c46;
  border-radius: 0.35rem 0 0 0.35rem;
  box-sizing: border-box;
  transition: all 0.3s;
  transform-origin: right;
  transform: scaleX(0);
  z-index: 20;
}

.explain {
  width: 1.64rem;
  height: 0.52rem;
  line-height: 0.52rem;
  top: 0.43rem;
  font-size: 0.24rem;
  color: #ffffff;
  text-align: center;
}

.userInfo {
  width: 2.22rem;
  top: 0.31rem;
  padding: 0.05rem;
  display: flex;
  align-items: center;
}
.userInfo img {
  aspect-ratio: 1/1;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.12rem;
}
.userInfo .name {
  font-size: 0.24rem;
  line-height: 0.24rem;
  margin-bottom: 0.05rem;
  color: #ffffff;
}
.userInfo .phone {
  font-size: 0.16rem;
  color: #fea6a2;
}
.userBoxPos {
  position: absolute;
  left: 1.2rem;
  top: 3rem;
  transition: transform 0.7s;
  transform: scale(0);
  transform-origin: center;
}
.userBoxShow {
  transform: scale(1);
}
.record {
  text-align: center;
  font-size: 0.28rem;
  color: #ffbdbb;
}
.btnCountShow :nth-child(1) {
  line-height: 1rem;
}
.btnCountShow :nth-child(2) {
  height: 0;
  overflow: hidden;
}
/* 规则面板 */
.rulesBoxPos {
  position: absolute;
  top: 1.67rem;
  left: 0.5rem;
  z-index: 10;
  height: 0rem;
  transform-origin: center;
  transition: all 0.7s;
  opacity: 0;
}
.rulesBoxShow {
  height: 9.4rem;
  opacity: 1;
}
.loginOut {
  position: absolute;
  font-size: 0.22rem !important;
  background: #e94c46;
  width: 1.2rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  border-radius: 0.25rem 0 0 0.25rem;
  top: 1.16rem;
  right: 0;
  font-size: 0.28rem;
  color: #ffffff;
}
</style>
