import Vue from 'vue'
import App from './App.vue'
import router from './router'
import httpRequest from '@/utils/httpRequest'
import VueCookie from 'vue-cookie'
import store from '@/store'

Vue.config.productionTip = false
Vue.prototype.$baseUrl = 'https://pdimage.obizlink.com/wap-image'
Vue.prototype.$http = httpRequest
Vue.prototype.$store = store

Vue.use(VueCookie)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
