<template>
  <div class="rules">
    <div class="topTitle">活动说明</div>
    <div>1.抽奖主办方</div>
    <div>
      抽奖的主办方是组织和管理抽奖活动的企业、组织或个人。主办方需遵守当地法律法规，确保抽奖活动的合法性和公正性
    </div>
    <div>2.参与资格</div>
    <div>
      参与者需要符合一定的资格要求才能参与抽奖活动，例如年龄限制、地域限制等。参与者需提供真实和有效的个人信息，以确保活动的公正性和奖品的发放。3.抽奖号码抽奖号码可以通过多种形式获取，例如购买特定产品、完成任务或在活动中获得。抽奖号码可以是数字、字母、符号或其组合。每个参与者可以获得一个或多个抽奖号码。
    </div>
  </div>
</template>

<script>
export default {
  name: "RulesView",
  components: {},
};
</script>

<style scoped>
.rules {
  width: 6.5rem;
  height: 9.4rem;
  background: #bc2825;
  border-radius: 0.2rem;
  border: 0.01rem solid #ffdb31;
  color: #ffcb83;
  box-sizing: border-box;
  font-size: 0.28rem;
  overflow: hidden;
  padding: 0 0.4rem;
  line-height: 0.58rem;
  background-image: url("../assets/img/rules_bg.png");
  background-size: cover;
}
.topTitle {
  font-size: 0.36rem;
  font-weight: bold;
  margin: 0.2rem 0;
  text-align: center;
}
</style>
