import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
// import qs from 'qs'
import merge from 'lodash/merge'
import { clearLoginInfo } from '@/utils'
import { Base64 } from '@/utils/Base64'
// import { Message } from 'element-ui'
import { getUUID } from '@/utils'

// 创建请求图片的uuid
localStorage.setItem('uuid', getUUID())

function make_base_auth(user, pass) {
  let tok = user + ':' + pass
  return 'Basic ' + Base64.encode(tok)
}

const baseUrl = 'https://pdappapi.obizlink.com'
// const baseUrl = 'http://172.20.10.4:8180'

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  // config.headers['token'] = Vue.cookie.get('token') // 请求头带上token

  let token = Vue.cookie.get('token')
  let newToken = token == null ? make_base_auth('mkPostalDrawApp', 'mkPostalDrawAppSecret') : 'bearer ' + Vue.cookie.get('token')

  config.headers.Authorization = newToken // 请求头带上 Authorization
  config.headers.deviceId = localStorage.getItem('uuid')

  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data && response.data.code === 500) {
    alert(response.data.msg)
  } else if (response.data && response.data.code === 401) { // 401, token失效
    clearLoginInfo()
    router.push({ name: 'login' })
  }
  return response.data
}, error => {
  console.log('error', error.response.data.code)
  if (error.response.data.code === 401) {
    // clearLoginInfo()
    router.push({ name: 'login' })
    // Message.error(error.response.data.msg)
  }
  // 服务器报错时清除token，登录就失效了，这个很重要，要保留
  Vue.cookie.delete('token')
  return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
  return baseUrl + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式 form json
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data

  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
