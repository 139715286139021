<template>
  <!-- 蒙层 -->
  <div class="mask" :class="{ maskHide: moveFlag }">
    <div class="content" :class="{ move: moveFlag }">
      <div class="bg"></div>
      <div v-if="RaffleInfo.count > 0" class="fireWorks"></div>
      <div class="raffleBox">
        <div class="close" @click="closeMsg"></div>
      </div>
      <div class="textContent text1">
        <template v-if="!isRaffleNumber(RaffleInfo.flag)">
          <div class="thank">{{ RaffleInfo.name }}</div>
          <div v-if="leftDrawCount > 0" class="hasCount">还有机会</div>
        </template>
        <template v-else>
          <div
            class="RaffleImg"
            :style="{ backgroundImage: `url(${RaffleInfo.imageUrl})` }"
          ></div>
        </template>
      </div>
      <div class="bottomText" v-if="isRaffleNumber(RaffleInfo.flag)">
        <div class="raffleMsg">恭喜抽中{{ RaffleInfo.name }}</div>
        <div class="raffleName">{{ RaffleInfo.description }}</div>
      </div>
      <div
        @click="closeMsg"
        :class="{ btnHide: btnHide }"
        class="btn"
        v-if="leftDrawCount > 0"
      >
        继续抽奖
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RaffleMsgView",
  props: {
    RaffleInfo: {
      type: Object,
      default: () => {},
    },
    leftDrawCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      moveFlag: false,
      btnHide: false,
    };
  },
  methods: {
    closeMsg() {
      this.btnHide = true;
      this.$emit("refresh");
      if (this.RaffleInfo.count > 0) {
        this.moveFlag = true;
        setTimeout(() => {
          this.moveFlag = false;
          this.$emit("closeMsg");
        }, 700);
      } else {
        this.$emit("closeMsg");
      }
    },
    isRaffleNumber(num) {
      return num === 1 || num === 2 || num === 3 || num === 4;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: background-color 1s ease-in-out;
}
.maskHide {
  background-color: rgba(0, 0, 0, 0);
}
.content {
  margin-top: 1.2rem;
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  transform: scale(1);
  animation: firstShow 0.4s ease-in-out;
}
@keyframes firstShow {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.move {
  animation: move 0.7s ease-in-out infinite alternate;
}
@keyframes move {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
    margin-top: 0.3rem;
  }
  100% {
    transform: scale(0);
    margin-top: 8.3rem;
  }
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/light.png");
  background-size: cover;
  animation: rotate 5s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fireWorks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/fireWorks.png");
  background-size: cover;
  animation: fireWorks 2s ease-in-out forwards;
  z-index: 5;
}
@keyframes fireWorks {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
    top: -0.5rem;
  }
  90% {
    opacity: 1;
  }
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

.raffleBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/raffleBox.png");
  background-size: cover;
}
.textContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
}
.text1 {
  top: 3.24rem;
  color: #dd392a;
}
.thank {
  font-size: 0.59rem;
  font-weight: bold;
}
.hasCount {
  font-size: 0.36rem;
}
.RaffleImg {
  width: 3.52rem;
  height: 3.52rem;
  background-size: cover;
  position: absolute;
  left: 50%;
  animation: upDown 1s ease-in-out infinite alternate;
}
/* 上下缓动动画 */
@keyframes upDown {
  0% {
    transform: translateY(0) translateX(-50%);
  }
  50% {
    transform: translateY(-0.1rem) translateX(-50%);
  }
  100% {
    transform: translateY(0) translateX(-50%);
  }
}
.close {
  width: 0.48rem;
  height: 0.48rem;
  background-image: url("../assets/img/close.png");
  background-size: cover;
  position: absolute;
  top: 1.72rem;
  right: 1.55rem;
  z-index: 7;
}
.bottomText {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  text-align: center;
}
.raffleMsg {
  font-size: 0.26rem;
  color: #ffc69e;
}
.raffleName {
  font-size: 0.4rem;
  color: #ead15f;
  line-height: 0.52rem;
  font-weight: 700;
}
.btn {
  width: 6rem;
  height: 1.29rem;
  background-image: url("../assets/img/btn.png");
  background-size: cover;
  position: absolute;
  bottom: -2.83rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 7;
  font-size: 0.51rem;
  color: #d72822;
  text-align: center;
  line-height: 1rem;
  font-weight: 700;
  transition: opacity 0.3s ease-in-out;
}
.btnHide {
  opacity: 0;
}
</style>
