<template>
  <div class="userBox">
    <div class="userInfo">
      <img class="avatar" :src="`${$baseUrl}/avatar${gender}.png`" alt="" />
      <div class="userTag">
        <div class="tagTitle">{{ customerType }}</div>
        <div class="tag tagLeft"></div>
        <div class="tag tagRight"></div>
      </div>
    </div>
    <div class="area">所属网点: {{ storeName }}</div>
    <div class="name">{{ realName }}</div>
    <div class="phone">{{ mobile }}</div>
    <div class="count">
      本年剩余抽奖次数: <span>{{ leftDrawCount }}</span
      >次
    </div>
  </div>
</template>

<script>
// 获取vuex仓库的用户信息
import { mapState } from "vuex";
export default {
  name: "UserView",
  data() {
    return {
      userInfo: {},
    };
  },
  // 组件显示时调用
  mounted() {
    // 获取用户信息
    this.$http({
      url: this.$http.adornUrl("/customer/info"),
      method: "get",
    }).then((data) => {
      // 保存用户数据
      this.$store.commit("user/updateRealName", data?.data.realName);
      this.$store.commit("user/updateStoreName", data?.data.storeName);
      this.$store.commit("user/updateCustomerType", data?.data.customerType);
      this.$store.commit("user/updateGender", data?.data.gender);
      this.$store.commit("user/updateLeftDrawCount", data?.data.leftDrawCount);
      this.$store.commit("user/updateMobile", data?.data.mobile);
    });
  },
  computed: {
    ...mapState({
      realName: (state) => state.user.realName,
      storeName: (state) => state.user.storeName,
      customerType: (state) => state.user.customerType,
      gender: (state) => state.user.gender,
      leftDrawCount: (state) => state.user.leftDrawCount,
      mobile: (state) => state.user.mobile,
    }),
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #b21d13;
}
.bg {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  padding-top: 3rem;
}
.userBox {
  width: 5.07rem;
  height: 4.79rem;
  margin: 0 auto;
  background-image: url("../assets/img/userBox.png");
  background-size: cover;
  position: relative;
  padding-top: 1.17rem;
  box-sizing: border-box;
  text-align: center;
}
.userInfo {
  position: absolute;
  width: 2.38rem;
  height: 2.38rem;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  box-sizing: border-box;
}
.avatar {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 50%;
}
.userTag {
  object-fit: cover;
  width: 1.36rem;
  height: 0.42rem;
  line-height: 0.35rem;
  position: absolute;
  bottom: 0.07rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.21rem;
  color: #c1791e;
  background: linear-gradient(90deg, #fdba6a 0%, #ffec96 100%);
  border-radius: 0.21rem;
  border: #fef9d1 solid 0.03rem;
  box-sizing: border-box;
  z-index: 3;
}
.tagTitle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #fdba6a 0%, #ffec96 100%);
  border-radius: 0.21rem;
  z-index: 2;
}
.tag {
  position: absolute;
  width: 0.34rem;
  height: 0.34rem;
  top: 0;
  background-image: url("/src/assets/img/tag.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}
.tagLeft {
  left: -0.22rem;
}
.tagRight {
  transform: scaleX(-1);
  right: -0.22rem;
}
.area {
  font-size: 0.24rem;
  color: #ca8261;
}
.name {
  margin-top: 0.22rem;
  font-size: 0.5rem;
  font-weight: 700;
  color: #161616;
}
.phone {
  margin-top: 0.07rem;
  font-weight: 200;
  font-size: 0.32rem;
  color: #973e3e;
  font-weight: 600;
}
.count {
  margin-top: 0.68rem;
  font-size: 0.3rem;
  color: #ffffff;
}
.count span {
  font-size: 0.5rem;
  font-weight: 900;
}
.btn {
  margin: 0 auto;
  margin-top: 2.56rem;
  width: 6rem;
  height: 1.5rem;
  background-image: url("../assets/img/btn.png");
  background-size: contain;
  text-align: center;
  line-height: 1rem;
  font-size: 0.5rem;
  font-weight: 900;
  color: #cd241a;
}
.record {
  margin-top: 0.19rem;
  text-align: center;
  font-size: 0.28rem;
  color: #ffbdbb;
}
</style>
