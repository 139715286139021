<template>
  <div class="winRecord">
    <div class="topTitle">我的中奖记录</div>
    <div class="content">
      <div class="item" v-for="(item, index) in myWinRecord" :key="index">
        <span>{{ item.drawTime }}</span>
        <span>{{ item.prizeInfo }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RulesView",
  data() {
    return {
      myWinRecord: [],
    };
  },
  mounted() {
    this.getMyWinRecord();
  },
  methods: {
    getMyWinRecord() {
      this.$http({
        url: this.$http.adornUrl("/customer/myWinRecord"),
        method: "get",
      }).then((data) => {
        if (data && data.code === 0) {
          this.myWinRecord = data.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.winRecord {
  width: 6.5rem;
  height: 9.4rem;
  background: #bc2825;
  border-radius: 0.2rem;
  border: 0.01rem solid #ffdb31;
  color: #ffcb83;
  box-sizing: border-box;
  font-size: 0.28rem;
  overflow: hidden;
  padding: 0 0.4rem;
  line-height: 0.58rem;
  background-image: url("../assets/img/rules_bg.png");
  background-size: cover;
}
.topTitle {
  font-size: 0.36rem;
  font-weight: bold;
  margin: 0.2rem 0;
  text-align: center;
}
.content {
  height: 8rem;
  overflow-y: auto;
}
.item {
  width: 100%;
  height: 0.7rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.3rem;
  margin-bottom: 0.04rem;
  background-color: #d73a32;
  border-radius: 0.1rem;
}
</style>
